exports.components = {
  "component---src-pages-400-js": () => import("./../../../src/pages/400.js" /* webpackChunkName: "component---src-pages-400-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-celebrity-js": () => import("./../../../src/pages/celebrity.js" /* webpackChunkName: "component---src-pages-celebrity-js" */),
  "component---src-pages-commercial-1-js": () => import("./../../../src/pages/commercial-1.js" /* webpackChunkName: "component---src-pages-commercial-1-js" */),
  "component---src-pages-commercial-js": () => import("./../../../src/pages/commercial.js" /* webpackChunkName: "component---src-pages-commercial-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-ethnic-js": () => import("./../../../src/pages/ethnic.js" /* webpackChunkName: "component---src-pages-ethnic-js" */),
  "component---src-pages-food-js": () => import("./../../../src/pages/food.js" /* webpackChunkName: "component---src-pages-food-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-jewellery-1-js": () => import("./../../../src/pages/jewellery-1.js" /* webpackChunkName: "component---src-pages-jewellery-1-js" */),
  "component---src-pages-jewellery-js": () => import("./../../../src/pages/jewellery.js" /* webpackChunkName: "component---src-pages-jewellery-js" */),
  "component---src-pages-personal-js": () => import("./../../../src/pages/personal.js" /* webpackChunkName: "component---src-pages-personal-js" */),
  "component---src-pages-table-top-js": () => import("./../../../src/pages/table-top.js" /* webpackChunkName: "component---src-pages-table-top-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */)
}

